import React, { useState } from "react";
import { AntdTable } from "../../components/custom/AntdTable";
import { TransactionsGraph } from "./TransactionsGraph";
import { AntdModal } from "../../components/custom/AntdModal";
import {getBoolFilterValue, getFilterValue} from "../../helpers";

export function TransactionsTab({ transactionsInfo }) {
	const [isModalVisible, setIsModalVisible] = useState(false);

	const columns = [
		{
			key: "plant",
			title: "Plant",
			dataIndex: "warehouse_name",
			onFilter: (value, record) => record["warehouse_name"] === value,
			filters: getFilterValue(transactionsInfo.tableData, "warehouse_name"),
		},
		{
			key: "sloc",
			title: "Storage Location",
			dataIndex: "storage_location_name",
			onFilter: (value, record) => record["storage_location_name"] === value,
			filters: getFilterValue(
				transactionsInfo.tableData,
				"storage_location_name"
			),
		},
		{
			key: "document_number",
			title: "Document Number",
			dataIndex: "document_number",
		},
		{
			key: "date",
			title: "Posting Date",
			dataIndex: "date",
		},
		{
			key: "movement_type",
			title: "Movement Type",
			dataIndex: "movement_type",
			onFilter: (value, record) => record["movement_type"] === value,
			filters: getFilterValue(transactionsInfo.tableData, "movement_type"),
		},
		{
			key: "document_type",
			title: "Document Type",
			dataIndex: "document_type",
			onFilter: (value, record) => record["document_type"] === value,
			filters: getFilterValue(transactionsInfo.tableData, "document_type"),
		},
		{
			key: "quantity",
			title: "Quantity",
			dataIndex: "Quantity",
		},
		{
			key: "description",
			title: "Description",
			dataIndex: "movement_type_text",
		},
		{
			key: "sdc_code",
			title: "SDC Code",
			dataIndex: "sdc_code",
		},
		{
			key: "tr_type",
			title: "Transaction Type",
			dataIndex: "tr_type",
			onFilter: (value, record) => record["tr_type"] === value,
			filters: getFilterValue(transactionsInfo.tableData, "tr_type"),
		},
		{
			key: "planned",
			title: "Planned",
			dataIndex: "pm",
			onFilter: (value, record) => record["pm"] === value,
			filters: getBoolFilterValue(transactionsInfo.tableData, "pm"),
			render: (pm) => pm ? 'Yes' : 'No'
		},
	];

	return (
		<>
			{transactionsInfo.graphData && transactionsInfo.graphData.length > 0 && (
				<div
					style={{ float: "right", color: "#004987" }}
					className="beautiful-link"
					onClick={() => setIsModalVisible(true)}
				>
					Open graph
				</div>
			)}
			<AntdTable
				tableSize={"small"}
				scroll={300}
				data={transactionsInfo.tableData}
				columns={columns}
			/>

			<AntdModal
				isModalVisible={isModalVisible}
				title={"Transaction Data"}
				onCancel={() => setIsModalVisible(false)}
				onOk={() => setIsModalVisible(false)}
			>
				<TransactionsGraph graphData={transactionsInfo.graphData} />
			</AntdModal>
		</>
	);
}
