import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap-theme.min.css";
import Menu from "./Menu";
import * as _ from "lodash";
import { Modal, Panel, PanelGroup, Tab, Table } from "react-bootstrap";
import "./Catalog.css";
import { RequestModal } from "./App";
import { properties, showContextLinks } from "./properties";
import Tabs from "react-bootstrap/lib/Tabs";
import { Line } from "react-chartjs-2";
import FuzzySet from "fuzzyset.js";
import Auth from "./Auth";
import { EntityLinksDropdown } from "./EntityLinks";
import api from "./api";

const FileDownload = require("js-file-download");

class AdditionalInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			open: false,
			table: [],
		};
	}

	createTable(info) {
		let table = (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th>Equipment</th>
						<th>Tag #</th>
						<th>Area</th>
						<th>KH Links</th>
					</tr>
				</thead>
				<tbody>
					{info.length > 0
						? info.map((v, i) => (
								<tr key={i}>
									<td>{v["Equipment"]}</td>
									<td>{v["Tag #"]}</td>
									<td>{v["Area"]}</td>
									<td>
										{v["links"].split(";").map((l, j) =>
											l !== "NA" ? (
												<a
													style={{ marginRight: 10 }}
													href={l}
													target="_blank"
													rel="noopener noreferrer"
													key={j}
												>
													Link {j + 1}
												</a>
											) : (
												<div />
											)
										)}
									</td>
								</tr>
							))
						: []}
				</tbody>
			</Table>
		);
		return table;
	}

	beautifyKey(key) {
		if (key === "Equipment (number of)") {
			return "Equipment (number of)";
		}
		return _.startCase(key.split("_").join(" "));
	}

	filter(item, element) {
		let arr = ["raw", "attrs", "url", "Unnamed: 0"];
		return (
			item[element] && item[element] !== "NA" && arr.indexOf(element) === -1
		);
	}

	getEquipmentTable(operator, material) {
		if (this.state.table.length === 0) {
			api
				.get(
					`/get/equipment?operator=${operator}&material=${material}`,
					Auth.headers()
				)
				.then((json) => {
					this.setState({
						open: true,
						table: this.createTable(json.data["equipment"]),
					});
				})
				.catch((error) => console.log(error));
		} else {
			this.setState({ open: !this.state.open });
		}
	}

	getValue(v, item) {
		if (v === "Equipment (number of)" && item[v] !== "NA") {
			return this.state.open ? (
				<div>
					<div
						style={{ color: "blue" }}
						onClick={() =>
							this.getEquipmentTable(item["operator"], item["material"])
						}
					>
						{item[v]}
					</div>
					<div
						style={{
							border: "1px solid blue",
							color: "black",
							width: "100%",
							maxHeight: "250px",
							overflowY: "auto",
						}}
					>
						{this.state.table}
					</div>
				</div>
			) : (
				<div
					style={{ color: "blue" }}
					onClick={() =>
						this.getEquipmentTable(item["operator"], item["material"])
					}
				>
					{item[v]}
				</div>
			);
		}
		return <div>{item[v]}</div>;
	}

	render() {
		let item = this.props.item;
		let columns = Object.keys(item);
		let mainColumns = this.props.mainColumns;

		let filtered = _.filter(columns, (element) => {
			return mainColumns.indexOf(element) === -1 && this.filter(item, element);
		});

		let rows = filtered.map((v, i) => (
			<div className="row">
				<div className="col-md-2">
					<b>{this.beautifyKey(v)}:</b>
				</div>
				<div className="col-md-10">
					{v === "stock_level" ? (
						<div onClick={() => this.props.request()}>{item[v]}</div>
					) : (
						<div>{this.getValue(v, item)}</div>
					)}
				</div>
			</div>
		));

		if (rows.length === 0) {
			return <div />;
		}

		return (
			<Panel bsStyle="primary">
				<Panel.Body>
					<h4>
						<b>Additional info</b>
					</h4>
					{rows}
				</Panel.Body>
			</Panel>
		);
	}
}

export class AddImageModal extends Component {
	constructor(props) {
		super(props);
		this.state = {
			progress: "",
			activeKey: "1",
			old: [],
			order: [],
			actives: [],
		};
	}

	componentDidMount() {
		this.get_ids();
	}

	setAsActive(i) {
		let data = {
			_id: this.state.order[i],
		};

		api
			.post(`/image/active`, data, Auth.headers())
			.then((response) => this.props.onClose())
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					console.log(error);
				}
			});
	}

	deleteImage(i) {
		let data = {
			_id: this.state.order[i],
		};

		api
			.post(`/image/delete`, data, Auth.headers())
			.then((response) => {
				alert("The image was successfully deleted");
				this.get_ids();
			})
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					console.log(error);
				}
			});
	}

	getImgById(id) {
		const config = Auth.headers();
		config["responseType"] = "blob";

		let data = {
			_id: id,
		};

		api
			.post(`/image/id`, data, config)
			.then((response) => {
				let matrixBlob = new Blob([response.data], { type: "image/jpg" });
				let fileReader = new FileReader();
				const header = response.headers["content-disposition"];
				const filename = /filename=(.*)/.exec(header)[1].replace(/"/g, "");
				fileReader.readAsDataURL(matrixBlob);
				fileReader.onload = () => {
					let result = fileReader.result;
					this.setState((state) => ({
						old: state.old.concat(
							<img
								style={{ maxHeight: 150 }}
								alt={"img-" + id}
								src={result}
							/>
						),
						order: state.order.concat(id),
						actives: state.actives.concat(filename === "True" ? "active" : ""),
					}));
				};
			})
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					console.log(error);
				}
			});
	}

	get_ids() {
		let data = {
			material: this.props.item.material,
			type: "img",
			operator: this.props.item.operator,
			part_number: this.props.item.part_number,
		};

		api
			.post(`/images`, data, Auth.headers())
			.then((response) => {
				this.setState({ ids: response.data, old: [], order: [], actives: [] });
				for (let id in response.data) {
					this.getImgById(response.data[id]);
				}
			})
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					console.log(error);
				}
			});
	}

	downloadImage(i) {
		const config = Auth.headers();
		config["responseType"] = "blob";

		let item = this.props.item;

		let data = {
			_id: this.state.order[i],
		};

		api
			.post(`/image/id`, data, config)
			.then((response) => {
				const filename = `${item.operator}-${item.material}-${item.part_number}.jpg`;
				FileDownload(response.data, filename, filename);
			})
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					console.log(error);
				}
			});
	}

	handleSelect(activeKey) {
		this.setState({ activeKey });
	}

	// getImageByUrl() {
	//     const data = {
	//         url: this.state.url,
	//         material: this.props.item.material,
	//         operator: this.props.item.operator,
	//         part_number: this.props.item.part_number
	//     }
	//
	//     api.post(`/image/url`, data, Auth.headers())
	//         .then(response => {
	//             alert('Image was uploaded')
	//             this.props.onClose()
	//         })
	//         .catch(error => {
	//             if (error.response.status === 401) {
	//                 alert('Your session expired! Please login again!');
	//                 Auth.logout();
	//                 this.props.history.replace(Auth.redirectToLogin());
	//             } else if (error.response.data) {
	//                 alert(error.response.data);
	//             } else {
	//                 console.log(error)
	//             }
	//         })
	//
	// }

	uploadFile(e) {
		const formData = new FormData();
		const input = e.target;
		if (input.files.length > 0) {
			for (let file of input.files) {
				formData.append(file.name, file);
			}
			const config = Auth.headers();
			config["Content-Type"] = "multipart/form-data";
			config["onUploadProgress"] = (progressEvent) => {
				this.setState({
					progress:
						progressEvent.loaded < progressEvent.total
							? "Uploading files..."
							: "Processing files...",
				});
			};
			api
				.post(
					`/upload/image?operator=${this.props.item.operator}&material=${this.props.item.material}&part_number=${this.props.item.part_number}`,
					formData,
					config
				)
				.then((resp) => {
					if (resp.data.message) {
						alert(resp.data.message);
					} else {
						alert("Your files were successfully uploaded!");
					}
					this.props.onClose();
				})
				.catch((error) => {
					if (error.response.status === 401) {
						alert("Your session expired! Please login again!");
						Auth.logout();
						this.props.history.replace(Auth.redirectToLogin());
					} else {
						alert(error.response.data.message);
					}
				})
				.finally(() => {
					input.value = "";
					this.setState({ progress: "" });
				});
		}
	}

	render() {
		let rows = this.state.old.map((v, i) => (
			<tr
				style={{
					backgroundColor: this.state.actives[i] === "active" ? "#ccffcc" : "",
				}}
			>
				<td>{v}</td>
				<td>
					<p>
						<a onClick={() => this.setAsActive(i)}>Set as active</a>
					</p>
					<p>
						<a onClick={() => this.downloadImage(i)}>Download</a>
					</p>
					<p>
						<a onClick={() => this.deleteImage(i)}>Delete</a>
					</p>
				</td>
			</tr>
		));

		return (
			<Modal
				show={this.props.show}
				onHide={() => this.props.onClose()}
				onClose={() => this.props.onClose()}
			>
				<Modal.Header
					bsStyle="primary"
					closeButton
				>
					<Modal.Title>Image Editor</Modal.Title>
				</Modal.Header>

				<PanelGroup
					accordion
					style={{ padding: 5 }}
					id="accordion-controlled-example"
					activeKey={this.state.activeKey}
					onSelect={(activeKey) => this.handleSelect(activeKey)}
				>
					<Panel eventKey="1">
						<Panel.Heading>
							<Panel.Title toggle>Upload image by file</Panel.Title>
						</Panel.Heading>
						<Panel.Body collapsible>
							<input
								type="file"
								onChange={(e) => this.uploadFile(e)}
								multiple
								disabled={this.state.progress !== ""}
							/>
						</Panel.Body>
						<Panel eventKey="2">
							{rows.length > 0 && (
								<Panel eventKey="3">
									<Panel.Heading>
										<Panel.Title toggle>
											Select previously uploaded image
										</Panel.Title>
									</Panel.Heading>
									<Panel.Body collapsible>
										<Table
											bordered
											condensed
											hover
											className="text-center"
										>
											<thead>
												<tr>
													<th className="text-center">Image</th>
													<th className="text-center">Action</th>
												</tr>
											</thead>
											<tbody>{rows}</tbody>
										</Table>
									</Panel.Body>
								</Panel>
							)}
						</Panel>
					</Panel>
				</PanelGroup>
			</Modal>
		);
	}
}

class MainInfo extends Component {
	constructor(props) {
		super(props);
		this.state = {
			links: [],
			hers: [],
			structured: [],
			showModal: false,
			open: false,
			table: [],
			trOpen: false,
			key: 11,
			count: 0,
			usage: [],
		};
		this.handleSelect = this.handleSelect.bind(this);
	}

	getHers(config) {
		api
			.post(
				`/hers/full-data?operator=${this.props.item.operator}&material=${this.props.item.material}`,
				{ url: this.props.item.url },
				config
			)
			.then((json) => {
				this.setState({ hers: json.data["records"] });
			})
			.catch((error) => console.log(error));
	}

	getStructured(config) {
		api
			.post(
				`/structured/full-data?operator=${this.props.item.operator}&material=${this.props.item.material}`,
				{ url: this.props.item.url },
				config
			)
			.then((json) => {
				this.setState({
					structured: json.data["records"],
					key: json.data["records"].length > 0 ? 0 : 11,
				});
			})
			.catch((error) => console.log(error));
	}

	createTable(info) {
		let table = (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th>Equipment</th>
						<th>Tag #</th>
						<th>Area</th>
						<th>KH Links</th>
					</tr>
				</thead>
				<tbody>
					{info.length > 0
						? info.map((v, i) => (
								<tr key={i}>
									<td>
										<a
											href={`/data?operator=${this.props.item.operator}&material=${v["Equipment"]}`}
										>
											{v["Equipment"]}
										</a>
									</td>
									<td>{v["Tag #"]}</td>
									<td>{v["Area"]}</td>
									<td>
										{v["links"].split(";").map((l, j) =>
											l !== "NA" ? (
												<a
													style={{ marginRight: 10 }}
													href={l}
													target="_blank"
													rel="noopener noreferrer"
													key={j}
												>
													Link {j + 1}
												</a>
											) : (
												<div />
											)
										)}
									</td>
								</tr>
							))
						: []}
				</tbody>
			</Table>
		);
		return table;
	}

	createRows(info, months) {
		const rows = [];
		const years = ["2019", "2018", "2017", "2016"];
		for (let i in years) {
			let row = info.find((x) => x.year === years[i]);
			if (row) {
				rows.push(
					<tr>
						<td className="text-center">{years[i]}</td>
						<td className="text-center">{row["total"]}</td>
						{months.map((v, j) => (
							<td
								className="text-center"
								key={j}
							>
								{row[v]}
							</td>
						))}
					</tr>
				);
			} else {
				rows.push(
					<tr>
						<td className="text-center">{years[i]}</td>
						<td />
						{months.map((v, j) => (
							<td key={j} />
						))}
					</tr>
				);
			}
		}

		return rows;
	}

	createUsageTable(info, months) {
		let table = (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th>Year</th>
						<th>Total</th>
						{months.map((v, i) => (
							<th key={i}>{v}</th>
						))}
					</tr>
				</thead>
				<tbody>{info.length > 0 ? this.createRows(info, months) : []}</tbody>
			</Table>
		);
		return table;
	}

	createTrTable(info) {
		let table = (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th>Posting Date</th>
						{/*<th>Time Of Entry</th>*/}
						<th>Movement Type</th>
						<th>Quantity</th>
						<th>Movement Type Text</th>
					</tr>
				</thead>
				<tbody>
					{info.length > 0
						? info.map(
								(v, i) =>
									v["Quantity"] != 0 && (
										<tr key={i}>
											<td>{v["Posting Date"].split(" ")[0]}</td>
											{/*<td>{v['Time of Entry']}</td>*/}
											<td>{v["Movement Type"]}</td>
											<td>{v["Quantity"]}</td>
											<td>{v["Movement Type Text"]}</td>
										</tr>
									)
							)
						: []}
				</tbody>
			</Table>
		);
		return table;
	}

	getTransactionsChartData() {
		const tr = this.props.graph;

		return {
			labels: tr.map((v, i) => v["Posting Date"].split(" ")[0]),
			datasets: [
				{
					label: "SOH",
					data: tr.map((v, i) => v["stock"]),
					borderColor: "blue",
					backgroundColor: "rgba(0, 255, 128, 0)",
					fill: false,
					stepped: true,
				},
				{
					label: "ROP / MIN",
					data: tr.map((v, i) => this.props.item["ROP / MIN"]),
					// fill: false,
					backgroundColor: "rgba(0, 255, 128, 0)",
					borderColor: "rgba(0, 255, 128, 0.2)",
				},
				{
					label: "MAX",
					data: tr.map((v, i) => this.props.item["MAX"]),
					fill: false,
					backgroundColor: "rgba(54, 162, 235,0)",
					borderColor: "rgba(255, 0, 0, 0.3)",
				},
			],
		};
	}

	getEquipmentTable(operator, material) {
		if (this.state.table.length === 0) {
			api
				.get(
					`/get/equipment?operator=${operator}&material=${material}`,
					Auth.headers()
				)
				.then((json) => {
					this.setState({
						open: true,
						table: this.createTable(json.data["equipment"]),
					});
				})
				.catch((error) => console.log(error));
		} else {
			this.setState({ open: !this.state.open });
		}
	}

	getUsageStatTable(operator, material) {
		if (this.state.usage.length === 0) {
			api
				.get(
					`/get/stats?operator=${operator}&material=${material}`,
					Auth.headers()
				)
				.then((json) => {
					this.setState({
						usage: this.createUsageTable(
							json.data["stats"],
							json.data["months"]
						),
						count: json.data["stats"][0]["count"],
					});
				})
				.catch((error) => console.log(error));
		}
	}

	getSOHTable() {
		const item = this.props.item;
		const items = this.props.items;

		if (items) {
			const rows = items.map((v, i) => (
				<tr
					className="text-center"
					key={i}
				>
					<td />
					<td>{v["plant"]}</td>
					<td>{v["plant_location"]}</td>
					<td>{v["Bin Location"]}</td>
					<td>{v["Unit"] || v["unit"]}</td>
					<td>{parseInt(v["plant_stock_level"])}</td>
					<td>
						{parseInt(v["Surplus"]) || parseInt(v["surplus_level"]) || ""}
					</td>
					<td>{v["MIN"] || v["ROP / MIN"]}</td>
					<td>{v["ROP"] || v["ROP / MIN"]}</td>
					<td>{v["lot_size"]}</td>
					<td>{v["rounding_value"]}</td>
					<td>{v["MAX"]}</td>
					<td>{v["MRP Type"]}</td>
					<td>{v["Lead Time in Days"]}</td>
				</tr>
			));

			let max = items.map((v) => parseInt(v.MAX)).reduce((a, b) => a + b, 0);
			let min = items
				.map((v) => parseInt(v["ROP / MIN"]))
				.reduce((a, b) => a + b, 0);
			let surplus = items
				.map((v) => parseInt(v["Surplus"]))
				.reduce((a, b) => a + b, 0);

			rows.push(
				<tr className="text-center">
					<td>
						<b>Total #</b>
					</td>
					<td />
					<td />
					<td />
					<td />
					<td>{item.stock_level}</td>
					<td>{surplus || ""}</td>
					<td>{min || ""}</td>
					<td />
					<td />
					<td />
					<td>{max || ""}</td>

					<td />
					<td />
				</tr>
			);
			rows.push(
				<tr className="text-center">
					<td>
						<b>Total $</b>
					</td>
					<td />
					<td />
					<td />
					<td />
					<td>
						{(
							item.stock_level * parseFloat(item["Unit Pirce in USD"]) || 0
						).toFixed(2)}
					</td>
					<td>
						{(surplus * parseFloat(item["Unit Pirce in USD"]) || 0).toFixed(2)}
					</td>

					<td>
						{(min * parseFloat(item["Unit Pirce in USD"]) || 0).toFixed(2)}
					</td>
					<td />
					<td />
					<td />
					<td>
						{(max * parseFloat(item["Unit Pirce in USD"]) || 0).toFixed(2)}
					</td>
					<td />
					<td />
				</tr>
			);

			return (
				<Table
					striped
					bordered
					condensed
					hover
					className="Items"
				>
					<thead>
						<tr>
							<th />
							<th>Plant</th>
							<th>Location</th>
							<th>Bin Location</th>
							<th>UOM</th>
							<th>Stock on Hand</th>
							<th>Surplus</th>
							<th>Min</th>
							<th>ROP</th>
							<th>Lot Size</th>
							<th>Rounding Value</th>
							<th>Max</th>
							<th>MRP Type</th>
							<th>Lead Time (days)</th>
						</tr>
					</thead>
					<tbody>{rows}</tbody>
				</Table>
			);
		}
	}

	componentDidMount() {
		let operator = new URLSearchParams(window.location.search).get("operator");
		let material = new URLSearchParams(window.location.search).get("material");
		api
			.get(
				`/get/khlinks?operator=${operator}&material=${material}`,
				Auth.headers()
			)
			.then((json) => {
				this.setState({ links: json.data["links"] });
				this.getEquipmentTable(operator, material);
			})
			.catch((error) => console.log(error));
		this.getUsageStatTable(operator, material);
	}

	substr(description, part_number) {
		let value = description;
		const from = value.indexOf(part_number.trim());
		const length = part_number.trim().length;
		if (from > -1) {
			value =
				value.substr(0, from) +
				"<strong>" +
				value.substr(from, length) +
				"</strong>" +
				value.substr(from + length);
		}

		return value;
	}

	generatorMatched(item, i) {
		let f = FuzzySet(item.description.split(","));
		let pn_original = f.get(item.part_number_original.trim())
			? f.get(item.part_number_original.trim())[0][1]
			: "";
		let pn_matched = f.get(item.part_number_matched.trim())
			? f.get(item.part_number_matched.trim())[0][1]
			: "";
		let descr = this.substr(item.description, pn_original);
		descr = this.substr(descr, pn_matched);
		item.url = this.props.item.url;
		return (
			<tr>
				<td>{i + 1}</td>
				<td className="text-center">
					<span className="Item-material ">
						<a
							href={`/data?operator=${item.operator}&material=${item.material}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{item.operator} {item.material}
						</a>
					</span>
				</td>
				<td style={{ wordBreak: "break-all" }}>
					<span dangerouslySetInnerHTML={{ __html: descr }}></span>
				</td>
				<td className="Item-part-number">
					<a
						href={`https://www.google.com/search?q=${item.part_number_original}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{item.part_number_original}
					</a>
				</td>
				<td className="text-center">
					<div onClick={() => (this.props.onlyView ? null : null)}>
						{item.stock_level} {item.unit}
					</div>
				</td>
			</tr>
		);
	}

	createCommonalityTable() {
		const matches = this.props.matchedRows;
		const item = this.props.item;
		const rows = [];
		let idx = 0;
		if (matches.length > 0) {
			for (let i = 0; i < matches.length; i++) {
				if (
					!(
						String(item.material) === matches[i].material &&
						item.operator === matches[i].operator
					)
				) {
					rows.push(this.generatorMatched(matches[i], idx));
					idx += 1;
				}
			}
		}
		return (
			<Table
				className="Items"
				bordered
				condensed
				hover
				style={{ margin: 0 }}
			>
				<thead>
					<tr>
						<th>#</th>
						<th>Material</th>
						<th>Description</th>
						<th>Part Number</th>
						<th>Stock Level</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		);
	}

	beautifyKey(key) {
		if (key === "cl_name") return "Material Class";
		if (key === "filename") return "";
		else if (key == "Unit Pirce in USD") {
			return "Price Per Unit (USD)";
		} else if (key == "Demand") {
			return "Demand Units PA";
		} else if (key === "Transactions PA (ave.)") {
			return "Transactions PA (ave.)";
		}
		if (key === "Equipment (number of)") {
			return "Equipment (number of)";
		}
		if (key === "No BOMs") {
			return "No BOMs";
		}
		return _.startCase(key.split("_").join(" "));
	}

	createLocationsTable() {
		return (
			<table className="text-center">
				<tbody className="text-center">
					{this.props.locationsData.map((v, i) => (
						<tr
							className="text-center"
							key={i}
						>
							<td>{v.stock_level} </td>
							<td style={{ paddingLeft: 5 }}> {v.location}</td>
						</tr>
					))}{" "}
				</tbody>
			</table>
		);
	}

	getValue(v, item) {
		if (v === "part_number") {
			return (
				<a
					href={`https://www.google.com/search?q=${item.part_number}`}
					target="_blank"
					rel="noopener noreferrer"
				>
					{item.part_number}
				</a>
			);
		} else if (v === "stock_level") {
			return (
				<div
					className="catalog-link"
					onClick={() => this.props.request()}
				>
					{item[v]}
				</div>
			);
		} else if (v === "tag" || v == "filename") {
			return null;
		} else if (v === "Unit Pirce in USD") {
			return item[v] !== "NA"
				? parseFloat(item[v] || 0).toFixed(2)
				: parseFloat(0).toFixed(2);
		} else if (v === "location") {
			return this.createLocationsTable();
		} else if (v === "Demand") {
			return Math.round(item.Demand) ? (
				<div>
					{Math.round(item.Demand)} {item.unit}{" "}
				</div>
			) : (
				<div />
			);
		} else if (v === "Transactions PA (ave.)") {
			return Math.round(item["Transactions PA (ave.)"]) || "";
		} else if (v === "Surplus") {
			return Math.round(item.Surplus);
		}
		if (v === "Equipment (number of)" && item[v] !== "NA") {
			return (
				<div
					style={{
						color: "black",
						width: "100%",
						maxHeight: "250px",
						overflowY: "auto",
					}}
				>
					{this.state.table}
				</div>
			);
		}

		if (v === "Num of Transactions" && this.props.transactions) {
			const options = {
				// responsive: true,
				interaction: {
					intersect: false,
					axis: "x",
				},
			};

			return (
				<div className="row">
					<div className={"col-md-8"}>
						<div
							style={{
								// border: '1px solid blue',
								color: "black",
								width: "95%",
								marginLeft: 15,
								display: "inline-flex",
								maxHeight: "250px",
								overflowY: "auto",
							}}
						>
							{this.createTrTable(this.props.transactions)}
						</div>
					</div>
					<div className="col-md-4">
						<Line
							data={this.getTransactionsChartData()}
							options={options}
						/>
					</div>
				</div>
			);
		} else if (v.indexOf("link") > -1 && item[v] !== "NA") {
			return (
				<a
					href={item[v]}
					target="_blank"
					rel="noopener noreferrer"
				>
					Link
				</a>
			);
		}
		return <div>{item[v] || ""}</div>;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.item !== this.props.item && this.props.item) {
			this.getImage();
			Auth.sendToOther((config) => {
				this.getHers(config);
				this.getStructured(config);
			});
		}
		if (prevState.show !== this.state.show && !this.state.show) {
			this.getImage();
		}
	}

	createHersTable() {
		const rows = this.state.hers.map((v, i) => (
			<tr key={i}>
				<td>
					<a
						href={`https://www.google.com/search?q=${v.part_number}`}
						target="_blank"
						rel="noopener noreferrer"
					>
						{v.part_number}
					</a>
				</td>
				<td>{v.manufacturer}</td>
			</tr>
		));

		return (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th style={{ width: "30%" }}>Part Number</th>
						<th>Manufacturer</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		);
	}

	createStructuredTable() {
		const rows = this.state.structured.map((v, i) => (
			<tr key={i}>
				<td>{v.key}</td>
				<td>{v.value}</td>
			</tr>
		));

		return (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th style={{ width: "30%" }}>Attribute Name</th>
						<th>Attribute Value</th>
					</tr>
				</thead>
				<tbody>{rows}</tbody>
			</Table>
		);
	}

	getImage() {
		const config = Auth.headers();
		config["responseType"] = "blob";

		let data = {
			material: this.props.item.material,
			type: "img",
			operator: this.props.item.operator,
			part_number: this.props.item.part_number,
		};

		api
			.post(`/image`, data, config)
			.then((response) => {
				let matrixBlob = new Blob([response.data], { type: "image/jpg" });
				let fileReader = new FileReader();
				fileReader.readAsDataURL(matrixBlob);
				fileReader.onload = () => {
					let result = fileReader.result;
					this.setState({ image: result });
				};
			})
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					console.log(error);
				}
			})
			.finally(() => this.props.onFinish());
	}

	onModalClose() {
		this.setState({ showModal: false, image: "" });
		this.getImage();
	}

	handleSelect(key) {
		this.setState({ key });
	}

	render() {
		let item = this.props.item;

		let excluded = ["filename", "tag"];
		if (item["data_mapper_link"] === "NA") {
			excluded.push("data_mapper_link");
		}

		let mainColumns = [
			"material",
			"operator",
			"description",
			"cl_name",
			"Manufacturer",
			"part_number",
			"Unit Pirce in USD",
		];
		let mainColumns2 = [
			"unit",
			"location",
			"Bin Location",
			"stock_level",
			"ROP / MIN",
			"MAX",
			"MRP Type",
			"Surplus",
		];
		let mainColumns3 = [
			"Movement Type",
			"Demand",
			"Transactions PA (ave.)",
			"Transactions last 4Y",
		];
		let mainColumns4 = ["Equipment (number of)"];
		let mainColumns5 = [
			"Duplicate",
			"Obsolete",
			"Insurance Spare",
			"Slow Moving",
		];
		let mainColumns6 = [
			"BOM Clean-up",
			"MM Clean-up",
			"No BOMs",
			"MIN MAX Optimise",
		];
		let mainColumns7 = ["Num of Transactions"];
		mainColumns = mainColumns.filter((x) => excluded.indexOf(x) === -1);

		let rows = mainColumns.map((v, i) => (
			<div className="row">
				<div className="col-md-2">
					<b>{this.beautifyKey(v)}:</b>
				</div>
				<div className="col-md-10">{this.getValue(v, item)}</div>
			</div>
		));

		// let rows2 = mainColumns2.map((v, i) => <div className="row">
		//     <div className='col-md-2'><b>{this.beautifyKey(v)}:</b></div>
		//     <div className='col-md-10'>
		//         {this.getValue(v, item)}</div>
		// </div>)

		let rows2 = this.getSOHTable();

		let rows3 = mainColumns3.map((v, i) => (
			<div className="row">
				<div className="col-md-2">
					<b>{this.beautifyKey(v)}:</b>
				</div>
				<div className="col-md-10">{this.getValue(v, item)}</div>
			</div>
		));

		rows3.push(
			<div
				style={{
					color: "black",
					width: "100%",
					maxHeight: "250px",
					overflowY: "auto",
				}}
			>
				{this.state.usage}
			</div>
		);

		let rows4 = mainColumns4.map((v, i) => (
			<div className="row">{this.getValue(v, item)}</div>
		));

		let rows5 = mainColumns5.map((v, i) => (
			<div className="row">
				<div className="col-md-2">
					<b>{this.beautifyKey(v)}:</b>
				</div>
				<div className="col-md-10">{this.getValue(v, item)}</div>
			</div>
		));

		let rows6 = mainColumns6.map((v, i) => (
			<div className="row">
				<div className="col-md-2">
					<b>{this.beautifyKey(v)}:</b>
				</div>
				<div className="col-md-10">{this.getValue(v, item)}</div>
			</div>
		));

		let rows7 = mainColumns7.map((v, i) => (
			<div className="row">{this.getValue(v, item)}</div>
		));

		if (this.state.links.length > 0) {
			rows.push(
				<div className="row">
					<div className="col-md-2">
						<b>KH Links:</b>
					</div>
					<div className="col-md-10">
						{this.state.links.map((v, i) => (
							<a
								style={{ marginRight: 10 }}
								href={v["kh_link"]}
								target="_blank"
								rel="noopener noreferrer"
								key={i}
							>
								P&ID
							</a>
						))}
					</div>
				</div>
			);
		}

		if (!("surplus_level" in item)) {
			rows.push(
				<div className="row">
					<div className="col-md-2">
						<b>Surplus Level:</b>
					</div>
					<div className="col-md-10">{0}</div>
				</div>
			);
		}

		const editing = !this.state.image ? (
			<a onClick={(event) => this.setState({ showModal: true })}>Add image</a>
		) : (
			<a onClick={(event) => this.setState({ showModal: true })}>
				<img
					alt="edit-image"
					src="/images/edit-user-blue.png"
				/>
			</a>
		);

		return (
			<div>
				<h4>
					<b>Description</b>
				</h4>
				<div style={{ display: "inline" }}>
					{this.state.image && (
						<img
							style={{ maxHeight: 300, verticalAlign: " top" }}
							src={this.state.image}
						/>
					)}
					{this.props.show && editing}
				</div>
				{rows}
				<Tabs
					activeKey={this.state.key}
					onSelect={(k) => this.handleSelect(k)}
				>
					{this.state.structured.length > 0 && (
						<Tab
							eventKey={0}
							title="Description"
							style={{ padding: 10 }}
						>
							{this.createStructuredTable()}
						</Tab>
					)}

					<Tab
						eventKey={11}
						title="Stock on Hand"
						style={{ padding: 10 }}
					>
						{/*<h4><b>Stock on Hand</b></h4>*/}
						{rows2}
					</Tab>
					<Tab
						eventKey={1}
						title={"Usage (" + this.state.count + ")"}
						style={{ padding: 10 }}
					>
						{/*<h4><b>Stock Movement</b></h4>*/}
						{rows3}
					</Tab>
					<Tab
						eventKey={2}
						title={"Where-Used (" + (item["Equipment (number of)"] || 0) + ")"}
						style={{ padding: 10 }}
					>
						{/*<h4><b>Bill of Material</b></h4>*/}
						{rows4}
					</Tab>
					<Tab
						eventKey={3}
						title="Dashboard Summary"
						style={{ padding: 10 }}
					>
						{/*<h4><b>Dashboard Summary</b></h4>*/}
						{rows5}
					</Tab>
					<Tab
						eventKey={4}
						title="Focus Summary"
						style={{ padding: 10 }}
					>
						{/*<h4><b>Focus Summary</b></h4>*/}
						{rows6}
					</Tab>
					<Tab
						eventKey={5}
						title={"Transactions (" + (item["Transactions last 4Y"] || 0) + ")"}
						style={{ padding: 10 }}
					>
						{/*<h4><b>Transactions</b></h4>*/}
						{rows7}
					</Tab>

					<Tab
						eventKey={9}
						title={
							"Potential Duplicates (" +
							(this.props.matchedRows.length > 0
								? this.props.matchedRows.length - 1
								: 0) +
							")"
						}
						style={{ padding: 10 }}
					>
						{this.createCommonalityTable()}
					</Tab>

					{this.state.hers.length > 0 && (
						<Tab
							eventKey={10}
							title={"HERS Records (" + (this.state.hers.length || 0) + ")"}
							style={{ padding: 10 }}
						>
							{this.createHersTable()}
						</Tab>
					)}
				</Tabs>

				{this.state.showModal && (
					<AddImageModal
						show={this.state.showModal}
						onClose={() => this.onModalClose()}
						item={this.props.item}
					/>
				)}
			</div>
		);
	}
}

export class FullData extends Component {
	constructor(props) {
		super(props);
		this.state = {
			item: {},
			matchedRows: [],
			mainColumns: [],
			transactions: [],
			graph: [],
			locationsData: [],
			show: false,
			load: false,
		};
	}

	getStock(config, url, operator, material) {
		api
			.post(
				`/search/connected?operator=${operator}&material=${material}&showAll=true`,
				{ url },
				config
			)
			.then((json) => {
				this.setState({ locationsData: json.data.connected });
			})
			.catch((error) => {
				console.log(error);
				this.setState({ connectedRows: null });
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				}
			});
	}

	sendRequest(config, url, operator, material) {
		api
			.post(
				`/search/report?operator=${operator}&material=${material}&showAll=${true}`,
				{ url },
				config
			)
			.then((json) => {
				this.setState({ matchedRows: json.data });
			})
			.catch((error) => {
				this.setState({ matchedRows: [] });
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				}
			});
	}

	componentDidMount() {
		let url = properties.apiUrl;
		if (
			sessionStorage.getItem("item_url") &&
			sessionStorage.getItem("item_url") !== null
		) {
			url = sessionStorage.getItem("item_url");
		} else if (
			localStorage.getItem("item_url") &&
			localStorage.getItem("item_url") !== null
		) {
			url = localStorage.getItem("item_url");
			sessionStorage.setItem("item_url", url);
		}
		let operator = new URLSearchParams(window.location.search).get("operator");
		let material = new URLSearchParams(window.location.search).get("material");

		Auth.sendToOther((config) => {
			api
				.post(
					`/item/full?material=${material}&operator=${operator}`,
					{ url },
					config
				)
				.then((json) => {
					console.log(json);
					this.setState({
						item: json.data.item[0],
						items: json.data.item,
						load: true,
						show: true,
						mainColumns: json.data.mainColumns,
						transactions: json.data.transactions,
						graph: json.data.graph,
					});

					if (json.data.item[0] && !json.data.item[0].hasOwnProperty("type"))
						json.data.item[0].type = "material";

					if (json.data.item[0].type === "material") {
						api
							.get(`/validate_permission?permission=superadmin`, config)
							.then((json) => {
								this.setState({ show: true });
							})
							.catch((error) => {
								console.log(error.response.data);
								api
									.get(`/validate_permission?permission=admin`, config)
									.then((json) => {
										this.setState({ show: true });
									})
									.catch((error) => {
										console.log(error.response.data);
									});
							});
						this.getStock(config, url, operator, material);
						this.sendRequest(config, url, operator, material);
					}
				})
				.catch((error) => {
					if (error.response && error.response.status === 401) {
						alert("Your session expired! Please login again!");
						Auth.logout();
						console.log(this.props.history);
						if (this.props.history) {
							this.props.history.replace(Auth.redirectToLogin());
						} else {
							window.location.href = Auth.redirectToLogin();
						}
					} else {
						window.location.href = "/search?search=" + material;
					}
				});
		});
	}

	render() {
		let item = this.state.item;
		if (item && !item.hasOwnProperty("raw")) item.raw = item.description;

		if (item && !item.hasOwnProperty("type")) item.type = "material";

		return (
			<div>
				<Menu history={this.props.history} />

				<div className="catalog-root center-block">
					<h3 className="text-center">
						Item: {item.operator} {item.material}{" "}
						{showContextLinks && (
							<EntityLinksDropdown
								operator={item.operator}
								sortCode={item.sort_field}
								material={item.material}
								type={item.type}
							/>
						)}
					</h3>
					<Panel bsStyle="primary">
						<Panel.Body>
							{item.type === "material" && (
								<MainInfo
									item={item}
									items={this.state.items}
									show={this.state.show}
									load={this.state.load}
									onFinish={() => this.setState({ load: false })}
									mainColumns={this.state.mainColumns}
									matchedRows={this.state.matchedRows}
									transactions={this.state.transactions}
									graph={this.state.graph}
									locationsData={this.state.locationsData}
									request={() => this.setState({ selectedItem: item })}
								/>
							)}
							{item.type === "equipment" && (
								<EquipmentInfo
									item={item}
									items={this.state.items}
									show={this.state.show}
									mainColumns={this.state.mainColumns}
									transactions={this.state.transactions}
									locationsData={this.state.locationsData}
									request={() => this.setState({ selectedItem: item })}
								/>
							)}
						</Panel.Body>
					</Panel>

					{/*<AdditionalInfo item={item}*/}
					{/*                mainColumns = {this.state.mainColumns} />*/}

					<RequestModal
						item={this.state.selectedItem}
						isCommonality={false}
						onClose={() => this.setState({ selectedItem: null })}
					/>
				</div>
			</div>
		);
	}
}

class EquipmentInfo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			links: [],
			showModal: false,
			open: false,
			table: [],
			trOpen: false,
			key: 0,
			materials: [],
			pm: [],
			cm: [],
			planned: [],
		};
		this.handleSelect = this.handleSelect.bind(this);
	}

	createMatsTable(info) {
		let table = (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th>Material</th>
						<th>Item</th>
						<th>Description</th>
						<th>Installed</th>
						<th>UOM</th>
						<th>Bin Location</th>
					</tr>
				</thead>
				<tbody>
					{info.length > 0
						? info.map((v, i) => (
								<tr key={i}>
									<td>
										<a
											href={`/data?operator=${this.props.item.operator}&material=${v["Material"]}`}
										>
											{v["Material"]}
										</a>
									</td>
									<td>{v["Item"]}</td>
									<td>{v["Description"]}</td>
									<td>{v["Installed"]}</td>
									<td>{v["UOM"]}</td>
									<td>{v["Bin Location"]}</td>
								</tr>
							))
						: []}
				</tbody>
			</Table>
		);
		return table;
	}

	createPMandCMTable(info) {
		let table = (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th>Order</th>
						<th>Sort Field</th>
						<th>Description</th>
						<th>ABC Indic.</th>
						<th>Created on</th>
					</tr>
				</thead>
				<tbody>
					{info.length > 0
						? info.map((v, i) => (
								<tr key={i}>
									<td>{v["Order"].split(" ")[0]}</td>
									<td>{v["Sort field"]}</td>
									<td>{v["Description"]}</td>
									<td>{v["ABC indic."]}</td>
									<td>{v["Created on"].split(" ")[0]}</td>
								</tr>
							))
						: []}
				</tbody>
			</Table>
		);
		return table;
	}

	createPlannedTable(info) {
		let table = (
			<Table
				striped
				bordered
				condensed
				hover
				className="Items"
			>
				<thead>
					<tr>
						<th>Maint. Item</th>
						<th>Sort Field</th>
						<th>Description</th>
						<th>ABC Indic.</th>
						<th>MaintItem text</th>
					</tr>
				</thead>
				<tbody>
					{info.length > 0
						? info.map((v, i) => (
								<tr key={i}>
									<td>{v["MaintItem"]}</td>
									<td>{v["Sort field"]}</td>
									<td>{v["Description"]}</td>
									<td>{v["ABC indic."]}</td>
									<td>{v["MaintItem text"]}</td>
								</tr>
							))
						: []}
				</tbody>
			</Table>
		);
		return table;
	}

	getUsageStatTable(operator, material) {
		if (this.state.materials.length === 0) {
			api
				.get(
					`/get/equipment-materials?operator=${operator}&material=${material}`,
					Auth.headers()
				)
				.then((json) => {
					this.setState({
						mats: this.createMatsTable(json.data["materials"] || []),
						materials: json.data["materials"] || [],
					});
				})
				.catch((error) => console.log(error));
		}
	}

	getPMTable() {
		if (this.state.pm.length === 0) {
			api
				.get(`/get/pm?sort=${this.props.item["sort_field"]}`, Auth.headers())
				.then((json) => {
					this.setState({ pm: json.data["materials"] });
				})
				.catch((error) => console.log(error));
		}
	}

	getCMTable() {
		if (this.state.cm.length === 0) {
			api
				.get(`/get/cm?sort=${this.props.item["sort_field"]}`, Auth.headers())
				.then((json) => {
					this.setState({ cm: json.data["materials"] });
				})
				.catch((error) => console.log(error));
		}
	}

	getPMPlannedTable() {
		if (this.state.planned.length === 0) {
			api
				.get(
					`/get/pm-planned?sort=${this.props.item["sort_field"]}`,
					Auth.headers()
				)
				.then((json) => {
					this.setState({ planned: json.data["materials"] });
				})
				.catch((error) => console.log(error));
		}
	}

	componentDidMount() {
		let operator = new URLSearchParams(window.location.search).get("operator");
		let material = new URLSearchParams(window.location.search).get("material");
		api
			.get(
				`/get/khlinks?operator=${operator}&material=${material}`,
				Auth.headers()
			)
			.then((json) => {
				this.setState({ links: json.data["links"] });
				this.getEquipmentTable(operator, material);
			})
			.catch((error) => console.log(error));
		this.getUsageStatTable(operator, material);
		this.getPMTable();
		this.getCMTable();
		this.getPMPlannedTable();
		this.getImage();
	}

	getValue(v, item) {
		if (v === "type") {
			return <div>{_.startCase(item[v].split("_").join(" "))}</div>;
		}
		return <div>{item[v]}</div>;
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (prevProps.item !== this.props.item && this.props.item) {
			this.getImage();
		}
		if (prevState.show !== this.state.show && !this.state.show) {
			this.getImage();
		}
	}

	getImage() {
		const config = Auth.headers();
		config["responseType"] = "blob";

		let data = {
			material: this.props.item.material,
			type: "img",
			operator: this.props.item.operator,
			part_number: this.props.item.part_number,
		};

		api
			.post(`/image`, data, config)
			.then((response) => {
				let matrixBlob = new Blob([response.data], { type: "image/jpg" });
				let fileReader = new FileReader();
				fileReader.readAsDataURL(matrixBlob);
				fileReader.onload = () => {
					let result = fileReader.result;
					this.setState({ image: result });
				};
			})
			.catch((error) => {
				if (error.response.status === 401) {
					alert("Your session expired! Please login again!");
					Auth.logout();
					this.props.history.replace(Auth.redirectToLogin());
				} else {
					console.log(error);
				}
			});
	}

	onModalClose() {
		this.setState({ showModal: false, image: "" });
		this.getImage();
	}

	handleSelect(key) {
		if (key === null) {
			window.open(`/proposed?q=${this.props.item.material}`, "_blank").focus();
		} else {
			this.setState({ key });
		}
	}

	beautifyKey(key) {
		return _.startCase(key.split("_").join(" "));
	}

	getPMPlansInterval() {
		if (this.state.planned.length === 0) {
			return "NO";
		}
		for (let i in this.state.planned) {
			let counter = this.state.planned[i]["Group Counter"];
			if (["6Y", "5Y", "4Y", "9Y"].indexOf(counter) > -1) {
				return "YES";
			}
		}
		return "NO";
	}

	render() {
		let item = this.props.item;

		let mainColumns = [
			"material",
			"type",
			"operator",
			"description",
			"manufacturer",
			"Area",
			"sort_field",
			"part_number",
			"cl_name",
		];

		let rows = mainColumns.map((v, i) => (
			<div className="row">
				<div className="col-md-2">
					<b>{this.beautifyKey(v)}:</b>
				</div>
				<div className="col-md-10">{this.getValue(v, item)}</div>
			</div>
		));

		let rows3 = [
			<div
				style={{
					color: "black",
					width: "100%",
					maxHeight: "250px",
					overflowY: "auto",
				}}
			>
				{this.state.mats}
			</div>,
		];

		const editing = !this.state.image ? (
			<a onClick={(event) => this.setState({ showModal: true })}>Add image</a>
		) : (
			<a onClick={(event) => this.setState({ showModal: true })}>
				<img
					alt="edit-image"
					src="/images/edit-user-blue.png"
				/>
			</a>
		);

		if (this.state.links.length > 0) {
			rows.push(
				<div className="row">
					<div className="col-md-2">
						<b>KH Links:</b>
					</div>
					<div className="col-md-10">
						{this.state.links.map((v, i) => (
							<a
								style={{ marginRight: 10 }}
								href={v["kh_link"]}
								target="_blank"
								rel="noopener noreferrer"
								key={i}
							>
								P&ID
							</a>
						))}
					</div>
				</div>
			);
		}

		const total = this.state.pm.length + this.state.cm.length;

		return (
			<div>
				<div style={{ display: "inline" }}>
					{this.state.image && (
						<img
							style={{ maxHeight: 300, verticalAlign: " top" }}
							src={this.state.image}
						/>
					)}
					{this.props.show && editing}
				</div>
				{rows}
				<Tabs
					activeKey={this.state.key}
					onSelect={(k) => this.handleSelect(k)}
				>
					<Tab
						eventKey={0}
						title="Information"
						style={{ padding: 10 }}
					>
						<div>
							<div className="row">
								<div className="col-md-2">
									<b>Components:</b>
								</div>{" "}
								{this.state.materials.length || 0}
							</div>
							<div className="row">
								<div className="col-md-2">
									<b>Identical BOMs:</b>
								</div>{" "}
								{parseInt(item["Identical BOMs"] || 0)}
							</div>
							<div className="row">
								<div className="col-md-2">
									<b>PM Maintenance Plans:</b>
								</div>{" "}
								{this.state.planned.length}
							</div>
							<div className="row">
								<div className="col-md-2">
									<b>PM Work Executed:</b>
								</div>
								{this.state.pm.length}
							</div>
							<div className="row">
								<div className="col-md-2">
									<b>CM Work Executed:</b>
								</div>
								{this.state.cm.length}
							</div>
							<div className="row">
								<div className="col-md-2">
									<b>PM:CM Ratio:</b>
								</div>
								{Math.round((this.state.pm.length / total) * 100) || 0}:
								{Math.round((this.state.cm.length / total) * 100) || 0}
							</div>
						</div>
					</Tab>
					<Tab
						eventKey={1}
						title={
							"Bill of Material (" + (this.state.materials.length || 0) + ")"
						}
						style={{ padding: 10 }}
					>
						{rows3}
					</Tab>
					<Tab
						eventKey={2}
						title={"PM Plan (" + (this.state.planned.length || 0) + ")"}
						style={{ padding: 10 }}
					>
						{this.createPlannedTable(this.state.planned)}
					</Tab>
					<Tab
						eventKey={3}
						title={"PM Executed (" + (this.state.pm.length || 0) + ")"}
						style={{ padding: 10 }}
					>
						{this.createPMandCMTable(this.state.pm)}
					</Tab>
					<Tab
						eventKey={4}
						title={"CM Executed (" + (this.state.cm.length || 0) + ")"}
						style={{ padding: 10 }}
					>
						{this.createPMandCMTable(this.state.cm)}
					</Tab>
					<Tab
						eventKey={5}
						title={"Health Check Dashboard"}
						style={{ padding: 10 }}
					>
						<div>
							<div className="row">
								<div className="col-md-3">
									<b>PM Plan no BOM:</b>
								</div>{" "}
								NO
							</div>
							<div className="row">
								<div className="col-md-3">
									<b>BOM no PM Plan:</b>
								</div>{" "}
								{this.state.planned.length ? "NO" : "YES"}
							</div>
							<div className="row">
								<div className="col-md-3">
									<b>Oversize BOM > 100 components:</b>
								</div>{" "}
								{this.state.materials.length > 100 ? "YES" : "NO"}
							</div>
							<div className="row">
								<div className="col-md-3">
									<b>Equipment with no Model Number:</b>
								</div>
								{item.part_number !== "NA" ? "NO" : "YES"}
							</div>
							<div className="row">
								<div className="col-md-3">
									<b>Component Returns:</b>
								</div>
								{item["Component Returns"]}
							</div>
							<div className="row">
								<div className="col-md-3">
									<b>PM:CM Ratio exceeds 60:40 ratio:</b>
								</div>
								{this.state.pm.length / this.state.cm.length > 60 / 40
									? "YES"
									: "NO"}
							</div>
							<div className="row">
								<div className="col-md-3">
									<b>PM Plan Maintenance Interval >= 4Y:</b>
								</div>
								{this.getPMPlansInterval()}
							</div>
						</div>
					</Tab>

					<Tab
						eventKey={null}
						title={
							<a
								href={`/proposed?q=${item.material}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								Proposed BOM
							</a>
						}
						style={{ padding: 10 }}
					></Tab>
				</Tabs>

				{this.state.showModal && (
					<AddImageModal
						show={this.state.showModal}
						onClose={() => this.onModalClose()}
						item={this.props.item}
					/>
				)}
			</div>
		);
	}
}
